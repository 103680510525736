import { useI18n } from 'vue-i18n';
import { relationships } from '~/models/relationships';

export const getRelationships = () => {
  const { t } = useI18n();
  return relationships.map((item) => {
    return {
      label: t(item.toLowerCase()),
      value: item.toUpperCase()
    }
  })
}